import { Component, OnInit } from '@angular/core';
import { TemplateService } from '../../core/services/template/template.service';
import { UserService } from '../../core/services/user/user.service';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { SeoService } from 'src/app/shared/services';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { LazyLoadService, LOADING_STRATEGY } from '@abp/ng.core';
import { concat } from 'rxjs';

@Component({
  selector: 'app-root',
  template: `
    <abp-loader-bar></abp-loader-bar>
    <abp-dynamic-layout></abp-dynamic-layout>
  `,
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private seoService: SeoService,
    templateService: TemplateService,
    private userService: UserService,
    private lazyLoadService: LazyLoadService
  ) {
    templateService.replaceComponent();
    this.setSeoValuesForPages();
  }

  ngOnInit(): void {
    this.userService.sendHeartBeat();
  }

  private setSeoValuesForPages(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data),
        tap(({ title, description }: Data) => {
          this.setupSeoTagsData(title, description);
          this.addNgxDataTableStyles(this.router.url);
        })
      )
      .subscribe();
  }

  private setupSeoTagsData(title: string, description: string): void {
    if (title && description) {
      this.seoService.updateTitle(title);
      this.seoService.updateDescription(description);
    } else {
      this.seoService.updateTitle('algoleague | competitive programming platform in Turkey');
      this.seoService.updateDescription(
        'algoleague is a competitive programming platform where algorithm enthusiasts of all levels practice their algorithmic thinking skills, and compete in contests both in teams and individually'
      );
    }
    this.seoService.setCanonicalTag(window.location.href);
  }

  private addNgxDataTableStyles(url: string): void {
    const administrationURLs = ['/identity/roles', '/identity/users', '/tenant-management/tenants'];
    const ngxStyles = [
      '../../../assets/css/ngx-datatable/ngx-datatable-index.css',
      '../../../assets/css/ngx-datatable/ngx-datatable-icons.css',
      '../../../assets/css/ngx-datatable/ngx-datatable-material.css',
      '../../../assets/css/ngx-datatable/ngx-datatable-custom.css',
    ];
    const loadedStyles = Array.from(this.lazyLoadService.loaded.keys());
    if (administrationURLs.some(x => x === url) && !loadedStyles.some(x => ngxStyles.includes(x))) {
      const ngxDataTableStyles$ = ngxStyles.map(style =>
        this.lazyLoadService.load(LOADING_STRATEGY.AppendAnonymousStyleToHead(style))
      );
      concat(...ngxDataTableStyles$).subscribe();
    }
  }
}
